<template>

  <DefaultCrudView
    @create-new="newSecurityGroup"
    :breadcrumbsItems="breadcrumbs"
    :actions="crudActions"
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="security_groups"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.tenant_id="{ item }">
          {{ getTenantTitle(item.tenant_id) }}
        </template>

        <template v-slot:item.match_type="{ item }">
          <span
            color="primary"
            dark
          >
            {{ item.match_type }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editSecurityGroup(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="editSecurityGroupFilter(item)"
              >
                mdi-filter-plus
              </v-icon>
            </template>
            <span>{{ $t('edit_filter') }}</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="modalDeleteSecurityGroup(item)"
              >
                 mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="marksSecurityGroup(item)"
              >
                 mdi-bookmark-multiple
              </v-icon>
            </template>
            <span>{{ $t('marks') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('delete_security_group')"
        ref="deleteModal"
        @submit="removeSecurityGroup"
        submit-button-text="delete"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('remove_security_group') }} '<b>{{ selectedSecurityGroup ? selectedSecurityGroup.security_group_key : '' }}</b>' ?
        </template>
      </DefaultModal>

      <FilterAssocModal 
        @submit-filter="saveSecurityGroupFilter"
        ref="filter_assoc_modal"
      />

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'
import FilterAssocModal from '@/components/node/FilterAssocModal'

import ActionActions from '@/store/operation/action/actions-types'
import SecurityGroupActions from '@/store/operation/security_group/actions-types'
import SecurityGroupMutations from '@/store/operation/security_group/mutations-types'
import AppActions from '@/store/app/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'

export default {
  name: "SecurityGroup",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
    FilterAssocModal
  },

  computed: {
    ...mapGetters('app', [
      'getIconForOperationSettingsItem'
    ]),

    ...mapState({
      fields: state => state.operation.security_group.fields,
      activesets: state => state.operation.security_group.security_groups,
      headers: state => state.operation.security_group.tableHeaders,
      selectedSecurityGroup: state => state.operation.security_group.selectedSecurityGroup
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('security_groups'),
          prependIcon: this.getIconForOperationSettingsItem('security'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      security_groups: [],
      options: {
        sortBy: ['security_group_key'],
        sortDesc: [false],
      },
      itemsLength: 0,
      crudActions: [
        {
          text: "create_security_group",
          eventToEmit: "create-new",
        }
      ],
      tenants: [],
      actions: []
    }
  },

  mounted() {
    this.[SecurityGroupMutations.SET_SELECTED_SECURITYGROUP](null)

    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[SecurityGroupMutations.SET_FIELD_TENANTS](tenants.items)
        this.tenants = tenants.items
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('operation/action', [
      ActionActions.GET_ALL_ACTIONS
    ]),

    ...mapActions('operation/security_group', [
      SecurityGroupActions.GET_ALL_SECURITYGROUPS,
      SecurityGroupActions.SAVE_SECURITYGROUP,
      SecurityGroupActions.UPDATE_SECURITYGROUP,
      SecurityGroupActions.DELETE_SECURITYGROUP,
      SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('operation/security_group', [
      SecurityGroupMutations.SET_FIELD_SECURITYGROUPS,
      SecurityGroupMutations.SET_SELECTED_SECURITYGROUP,
      SecurityGroupMutations.SET_FIELD_TENANTS,
      SecurityGroupMutations.HIDE_EDIT_FIELDS,
    ]),

    newSecurityGroup() {
      this.[SecurityGroupMutations.SET_SELECTED_SECURITYGROUP](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this[SecurityGroupActions.SAVE_SECURITYGROUP]()
        .then(() => {
          let message = this.selectedSecurityGroup ? 'SecurityGroup edited' : 'SecurityGroup created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)
      this.loading = true
      this[SecurityGroupActions.GET_ALL_SECURITYGROUPS](options)
        .then((result) => {
          this.security_groups = result.items
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    editSecurityGroup(security_group) {
      this[SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP](security_group.security_group_id)
        .then((result) => {
          this[SecurityGroupMutations.SET_SELECTED_SECURITYGROUP](security_group)

          if (result.items.length > 0) {
            this[SecurityGroupMutations.HIDE_EDIT_FIELDS]()
          }

          this.$refs.modal.openModal()
        })
    },

    modalDeleteSecurityGroup(security_group) {
      this[SecurityGroupMutations.SET_SELECTED_SECURITYGROUP](security_group)
      this.$refs.deleteModal.openModal()
    },

    removeSecurityGroup() {
      this[SecurityGroupActions.DELETE_SECURITYGROUP]()
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('security_group_deleted'))

          this.$refs.deleteModal.closeModal()

          this.reloadData()
        })
        .catch(() => this.$refs.deleteModal.submitting = false)
    },

    editSecurityGroupFilter(security_group) {
      this[SecurityGroupMutations.SET_SELECTED_SECURITYGROUP](security_group)

      this.$refs.filter_assoc_modal.tenantIdFilter = security_group.tenant_id

      this.$refs.filter_assoc_modal.open(security_group.filter_id)
    },

    saveSecurityGroupFilter(form) {
      const params = {
        filter_id: parseInt(form.get('filter')) || null
      }

      this[SecurityGroupActions.UPDATE_SECURITYGROUP](params)
        .then(() => {
          this.$refs.filter_assoc_modal.loading = false

          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('filter_updated'))

          this.$refs.filter_assoc_modal.close()

          this.reloadData()
        })
        .catch(() => this.$refs.filter_assoc_modal.loading = false)
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getModalTitle() {
      return this.selectedSecurityGroup ? this.$t('edit_security_group') : this.$t('new_security_group')
    },

    marksSecurityGroup(item) {
      this.[SecurityGroupMutations.SET_SELECTED_SECURITYGROUP](item)
      this.$router.push({name: 'SecurityMark', params: {securityGroupId: item.security_group_id}})
    }
  }
}

</script>